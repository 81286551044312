<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <el-form-item label="角色名称：" prop="roleName">
      <el-input
        v-model.trim="addForm.roleName"
        placeholder="请输入角色名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="所属公司：" prop="companyId">
      <companySelect
        ref="companySelect"
        :clearable="true"
        :companyTree="companyList"
        :valueName="valueNameAdd"
        :value="valueAdd"
        @getValue="getAddGroupId"
      ></companySelect>
    </el-form-item>
    <el-form-item label="系统：" prop="systems">
      <el-select
        v-model="addForm.systems"
        clearable
        multiple
        placeholder="请选择系统"
      >
        <el-option
          v-for="item in menuLabelList"
          :label="item.menuName"
          :value="item.id"
          :key="item.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="备注：" prop="remark">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2 }"
        maxlength="50"
        show-word-limit
        placeholder="请输入内容"
        v-model.trim="addForm.remark"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { checkName } from '@/common/utils/index.js'
import {
  insertRole,
  updateRole,
  getAuthCompanyTree,
  getMenuLabelList
} from '@/api/lib/api.js'

import companySelect from '@/components/treeSelect/companySelect.vue'
export default {
  components: {
    companySelect
  },
  props: {
    rowData: {
      type: Object,
      default: () => {}
    },
    companyName: {
      type: String
    }
  },
  data() {
    return {
      isEdit: false,
      companyList: [],
      menuLabelList: [],
      valueNameAdd: null,
      valueAdd: null,
      addForm: {
        id: '',
        roleName: '',
        companyId: '',
        systems: [],
        remark: '',
        status: ''
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        systems: [{ required: true, message: '请选择系统', trigger: 'blur' }],
        companyId: [
          { required: true, message: '请选择所属公司', trigger: 'change' }
        ]
      }
    }
  },

  methods: {
    // 获取企业树
    getCompanyList() {
      getAuthCompanyTree({ isPermission: 0 }).then((res) => {
        if (res.code === 1000) {
          this.companyList = [res.data]
        }
      })
    },
    // 获取系统列表
    getMenuLabelList() {
      getMenuLabelList({ id: 0, type: 0 }).then((res) => {
        if (res.code === 1000) {
          this.menuLabelList = res.data
        }
      })
    },

    getAddGroupId(v) {
      this.addForm.companyId = v
    },

    // 新增/修改角色
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            ...this.addForm,
            roleName: this.addForm.roleName,
            remark: this.addForm.remark
          }
          if (!this.isEdit) {
            data.status = 0
            insertRole(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            updateRole(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    }
  },
  created() {
    this.getCompanyList()
    this.getMenuLabelList()
  },
  mounted() {
    if (
      JSON.stringify(this.rowData) !== '{}' &&
      JSON.stringify(this.rowData) !== 'null'
    ) {
      this.addForm = { ...this.rowData }
      delete this.addForm.createTime
      delete this.addForm.updateTime
      delete this.addForm.createUserId
      delete this.addForm.systemNames

      this.valueAdd = this.rowData.companyId
      this.valueNameAdd = this.rowData.companyName
      this.addForm.systems = Array.from(this.rowData.systems, (item) =>
        parseInt(item)
      )

      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
}
</script>
