<template>
  <div class="role-management" ref="roleManagement">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="角色名称：">
          <el-input
            v-model.trim="form.roleName"
            placeholder="角色名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="text-right">
          <el-button
            type="primary"
            size="small"
            @click="onAddRole"
            v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="companyName" label="所属公司"></el-table-column>
      <el-table-column
        prop="systemNames"
        label="所属系统"
        :show-overflow-tooltip="true"
        width="400"
      >
        <template slot-scope="scope">
          {{ scope.row.systemNames.join('、') }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button
            @click="onAuthorizeClick(scope.row)"
            type="text"
            size="small"
            sort="authorize"
          >授权</el-button>-->
          <el-button
            @click="onUpdateRole(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.status === 0
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'danger' : 'primary'"
            @click="onDisableRole(scope.row)"
            v-if="
              scope.row.status === 0
                ? $store.state.menu.nowMenuList.indexOf('禁用') >= 0
                : $store.state.menu.nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <addRole
        ref="addRole"
        :rowData="rowData"
        :companyName="companyName"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></addRole>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import companySelect from '@/components/treeSelect/companySelect.vue'
import addRole from './components/addRole.vue'
import {
  updateStatusRole,
  queryRoleList,
  getAuthCompanyTree
} from '@/api/lib/api.js'

export default {
  name: 'roleManagement',
  components: {
    companySelect,
    addRole
  },
  data() {
    return {
      rowData: null,
      companyName: '',
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,

      form: {
        roleName: '',
        companyId: '',
        currentPage: 1,
        pageSize: 10
      },

      total: 0,
      dialogVisible: false,
      title: '',
      companyList: [],
      editLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.roleManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10
    },
    // 提交
    onSubmit() {
      this.form.currentPage = 1
      this.getRolesList()
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getRolesList()
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.getRolesList()
    },

    // 点击表格授权按钮
    onAuthorizeClick(row) {
      console.log(row)
    },

    //  禁用|启用 角色
    onDisableRole(row) {
      if (row.status === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '确定禁用角色 '),
            h('span', { style: 'color: #059370' }, row.roleName),
            h('span', null, ' ？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            updateStatusRole({ id: row.id, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('禁用成功')
                row.status = 1
              } else {
                this.$message.error('该角色还有用户使用，无法禁用')
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消禁用'
            })
          })
      } else {
        updateStatusRole({ id: row.id, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 点击新增角色
    onAddRole() {
      this.title = '新增角色'
      this.rowData = null
      this.companyName = ''
      this.dialogVisible = true
    },

    // 点击表格查看按钮
    onUpdateRole(row) {
      this.title = '修改角色'
      this.rowData = row
      this.companyName = row.companyName
      this.dialogVisible = true
    },
    // 新增/修改角色
    onSave() {
      this.$refs.addRole.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSubmit()
        } else {
          this.getRolesList()
        }
      }
      this.editLoading = false
    },
    // 获取角色列表
    getRolesList() {
      queryRoleList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getGroupId(v) {
      this.form.companyId = v
    },

    // 获取权限企业树
    getCompanyList() {
      getAuthCompanyTree({ isPermission: 0 }).then((res) => {
        if (res.code === 1000) {
          this.companyList = [res.data]
        }
      })
    }
  },
  created() {
    this.getCompanyList()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSubmit()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.role-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-resourse {
  .role-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
